import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import School from 'pages/School';
import Order from 'pages/Order';
import Edition from 'pages/Edition';
import User from 'pages/User';
import Report from 'pages/Report';
import Newspaper from 'pages/Newspaper';
import Express from 'pages/Express';
import Stock from 'pages/Stock';
import Stats from 'pages/Stats';

export default function App() {
  return (
    <Switch>
      <Redirect exact from="/" to="/report" />
      <Route path="/school" component={School} />
      <Route path="/order" component={Order} />
      <Route path="/edition" component={Edition} />
      <Route path="/user" component={User} />
      <Route path="/report" component={Report} />
      <Route path="/newspaper" component={Newspaper} />
      <Route path="/express" component={Express} />
      <Route path="/stock" component={Stock} />
      <Route path="/stats" component={Stats} />
    </Switch>
  );
}

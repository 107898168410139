import React, { useState } from 'react';
import { Table, Card, Row, Col, Typography, Form, Select, Button } from 'antd';
import { useRequest } from 'ahooks';
import axios from 'axios';
import dayjs from 'dayjs';

import StockModal from 'pages/Stock/Modal';

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

export default function List() {
  const [year, setYear] = useState('');
  const [term, setTerm] = useState('');
  const [grade, setGrade] = useState('');
  const { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) =>
      axios.get('/api/stock/listin', {
        params: {
          page: current,
          size: pageSize,
          year,
          term,
          grade,
        },
      }),
    {
      paginated: true,
      defaultPageSize: 15,
    }
  );

  return (
    <Card bordered={false}>
      <Row justify="space-between" style={{ marginBottom: 12 }}>
        <Col>
          <h2>入库管理</h2>
        </Col>
        <Col>
          <Row>
            <Col>
              <Form layout="inline">
                <Form.Item label="学年">
                  <Select
                    allowClear
                    placeholder="请选择学年"
                    style={{ width: 140 }}
                    onChange={(value) => setYear(value)}
                  >
                    <Option value={2021}>2020-2021年</Option>
                    <Option value={2022}>2021-2022年</Option>
                    <Option value={2023}>2022-2023年</Option>
                    <Option value={2024}>2023-2024年</Option>
                    <Option value={2025}>2024-2025年</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="学期">
                  <Select
                    allowClear
                    style={{ width: 120 }}
                    placeholder="请选择学期"
                    onChange={(value) => setTerm(value)}
                  >
                    <Option value={1}>上学期</Option>
                    <Option value={2}>下学期</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="年级" name="grade">
                  <Select
                    allowClear
                    style={{ width: 140 }}
                    placeholder="请选择班级"
                    onChange={(value) => setGrade(value)}
                  >
                    <Option value={1}>一年级</Option>
                    <Option value={2}>二年级</Option>
                    <Option value={3}>三年级</Option>
                    <Option value={4}>四年级</Option>
                    <Option value={5}>五年级</Option>
                    <Option value={6}>六年级</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button onClick={() => refresh()}>查询</Button>
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <StockModal onSuccess={() => refresh()} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        rowKey="id"
        bordered
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          total: data?.data?.count,
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column title="报纸" dataIndex="newspaperName" key="newspaperName" />
        <Column title="科目" dataIndex="subject" key="subject" />
        <Column title="版本" dataIndex="editionName" key="editionName" />
        <Column
          title="学年"
          dataIndex="year"
          key="year"
          render={(year) => `${year - 1}-${year}年`}
        />
        <Column
          title="学期"
          dataIndex="term"
          key="term"
          render={(term) => (term === 1 ? '上学期' : '下学期')}
        />
        <Column
          title="年级"
          key="grade"
          dataIndex="grade"
          render={(grade) => `${grade}年级`}
        />
        <Column title="期数" key="current" dataIndex="current" />
        <Column
          title="入库数量"
          key="count"
          dataIndex="count"
          render={(count) => (
            <Text type={count > 0 ? 'default' : 'danger'}>{count}</Text>
          )}
        />
        <Column title="入库人" key="userName" dataIndex="userName" />
        <Column
          title="入库时间"
          key="time"
          dataIndex="time"
          render={(time) => dayjs(time).format('YYYY-MM-DD HH:mm')}
        />
      </Table>
    </Card>
  );
}

import React from 'react';
import { Table, Card, Row, Col } from 'antd';
import { useRequest } from 'ahooks';

import EditionModal from './Modal';

const { Column } = Table;

export default function List() {
  const { data, loading, refresh } = useRequest('/api/newspaper/list');

  return (
    <Card bordered={false}>
      <Row justify="space-between" style={{ marginBottom: 12 }}>
        <Col>
          <h2>报纸管理</h2>
        </Col>
      </Row>
      <Table
        bordered
        rowKey="id"
        dataSource={data?.data}
        loading={loading}
        pagination={false}
      >
        <Column title="名称" dataIndex="title" key="title" />
        <Column title="描述" dataIndex="description" key="description" />
        <Column title="半年" dataIndex="half" key="half" />
        <Column title="全年" dataIndex="full" key="full" />
        <Column
          width={120}
          title="操作"
          key="action"
          dataIndex="id"
          render={(id) => <EditionModal id={id} onClose={() => refresh()} />}
        />
      </Table>
    </Card>
  );
}

import React, { Fragment, useState } from 'react';
import { Modal, Form, Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

export default function HalfModal({ onSuccess, ...props }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { id, half, currentTerm, ...restProps } = props;

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onFinish = (values) => {
    axios
      .put(`/api/school/${id}`, { ...values, ...restProps })
      .then(({ code, msg }) => {
        if (code === 0) {
          onSuccess();
          setVisible(false);
        }
        message[code ? 'error' : 'success'](msg);
      });
  };

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>设置订阅</a>
      <Modal
        title="设置订阅"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{ half, currentTerm }}
        >
          <Form.Item label="订阅年限" name="half" rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Option value={1}>半年</Option>
              <Option value={2}>全年</Option>
            </Select>
          </Form.Item>
          <Form.Item label="订阅学期" name="currentTerm" rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Option value={0}>智能识别</Option>
              <Option value={1}>上学期</Option>
              <Option value={2}>下学期</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

import React, { useState, useEffect } from 'react';
import { Descriptions } from 'antd';
import axios from 'axios';

export default function DetailSchool({ id }) {
  const [school, setSchool] = useState({});

  useEffect(() => {
    axios.get(`/api/school/${id}`).then(({ data }) => {
      setSchool(data);
    });
  }, [id]);

  return (
    <Descriptions bordered column={2} title="配送信息" size="small">
      <Descriptions.Item label="接报人">{school.recipients}</Descriptions.Item>
      <Descriptions.Item label="联系方式">{school.phone}</Descriptions.Item>
      <Descriptions.Item label="接报点">
        {school.receiveAddress}
      </Descriptions.Item>
      <Descriptions.Item label="送报方式">
        {school.receiveMode}
      </Descriptions.Item>
    </Descriptions>
  );
}

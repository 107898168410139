import React from 'react';
import { Cascader } from 'antd';
import { useRequest } from 'ahooks';
export default function Area(props) {
  function toTree(arr, parentId) {
    function loop(parentId) {
      return arr.reduce((acc, cur) => {
        if (cur.pid === parentId){
          cur.children = loop(cur.id)
          acc.push(cur)
        }
        return acc
      }, [])
    }
    return loop(parentId)
  }
  const { data, loading } = useRequest(`/api/Area/All`, {
    formatResult: ({ data }) => [{id: 410000,"name": "河南省","children": toTree(data,410000)}]
  });

  if (loading) return null;
  return (
    <Cascader
      options={data}
      fieldNames={{ label: 'name', value: 'id', children: 'children' }}
      placeholder="选择省市区"
      {...props}
    />
  );
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import StockIn from './StockIn';
import StockOut from './StockOut';

export default function News() {
  return (
    <Switch>
      <Route exact path="/stock" component={List} />
      <Route path="/stock/in" component={StockIn} />
      <Route path="/stock/out" component={StockOut} />
    </Switch>
  );
}

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';

export default function UserSelect(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get('/api/account/list?size=100&role=salesman').then(({ data }) => {
      setOptions(
        data.items.map(({ id, name }) => ({ label: name, value: id }))
      );
    });
  }, []);

  if (!options.length) {
    return (
      <Select
        loading={true}
        placeholder="请选择发行员"
        style={{ width: 160 }}
      />
    );
  }
  return (
    <Select
      options={options}
      placeholder="请选择发行员"
      style={{ width: 160 }}
      {...props}
    />
  );
}

import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import axios from 'axios';

export default function UserModal({ id, onClose }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onFinish = (values) => {
    axios({
      url: `/api/edition/${id || ''}`,
      method: id ? 'PUT' : 'POST',
      data: values,
    }).then(({ code, msg }) => {
      if (code === 0) {
        onClose();
        setVisible(false);
        return message.success('操作成功');
      }
      message.error(msg);
    });
  };

  useEffect(() => {
    if (id && visible) {
      axios.get(`/api/edition/${id}`).then(({ data }) => {
        form.setFieldsValue(data);
      });
    }
  }, [id, visible, form]);

  return (
    <Fragment>
      {id ? (
        <a onClick={() => setVisible(true)}>编辑</a>
      ) : (
        <Button type="primary" onClick={() => setVisible(true)}>
          新增版本
        </Button>
      )}
      <Modal
        title="版本管理"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form {...layout} form={form} hideRequiredMark onFinish={onFinish}>
          <Form.Item label="版本名称" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="简称" name="shortName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

import React, { Fragment, useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, message, Drawer,Spin } from 'antd';
import Area from 'components/Area';
import axios from 'axios';
import AMapLoader from '@amap/amap-jsapi-loader';
import './Map.css';
export default function UserModal({ id, onSuccess }) {
  const [form] = Form.useForm();
  const [area, setArea] = useState({});
  const [location, setLocation] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onAreaChange = (value, [province, city, district]) => {
    setArea({
      pid: province.id,
      province: province.name,
      cid: city.id,
      city: city.name,
      did: district.id,
      district: district.name,
    });
  };

  const onFinish = (values) => {
    console.log('提交的数据',{ ...values, ...area,...location })
    axios({
      url: `/api/school/${id || ''}`,
      method: id ? 'PUT' : 'POST',
      data: { ...values, ...area,location:location }
    }).then(({ code, msg }) => {
      if (code === 0) {
        onSuccess();
        setVisible(false);
        return message.success('添加成功');
      }
      message.error(msg);
    });
  };
  const generateAMap = () => {
    window._AMapSecurityConfig = {
      securityJsCode: '97dccf21fa3f3873216fdd1867b1fe22',
    }
    return new Promise((resolve, reject) => {
      if (window.AMap) {
        resolve(window.AMap)
        return
      }
      AMapLoader.load({
        "key": "26fe7ab1e82c65d2040f6396827ece66",              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ['AMap.Geolocation'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        "AMapUI": {             // 是否加载 AMapUI，缺省不加载
          "version": '1.1',   // AMapUI 版本
          "plugins": ['misc/PoiPicker'],       // 需要加载的 AMapUI ui插件
        },
      }).then((AMap) => {
        resolve(window.AMap)
      }).catch(e => {
        reject(e);
      })
    })

  }
  const initMap = (center) => {
    generateAMap().then(AMap => {
      const map = new AMap.Map('amp-container', {
        zoom: 12,
        ...center ? center : [],
      })
      var poiPicker = new window.AMapUI.PoiPicker({
        input: 'amp-searchInput',
        placeSearchOptions: {
          map: map,
          pageSize: 4
        },
        searchResultsContainer: 'amp-searchResults'
      });

        poiPicker.on('poiPicked', function (poiResult) {
          setLocation({type:'Point',coordinates:[poiResult.item.location.lng,poiResult.item.location.lat]});
          poiPicker.hideSearchResults();
          var source = poiResult.source,
            poi = poiResult.item;

          if (source !== 'search') {

            //suggest来源的，同样调用搜索
            poiPicker.searchByKeyword(poi.name);

          } else {
            console.log(2,form);
          }
        });

        poiPicker.onCityReady(function () {
          poiPicker.searchByKeyword(form.getFieldValue('name'));
        });

      map.addControl(new AMap.Geolocation())
      setLoading(false)
    })
  }
  useEffect(() => {
    if (id && visible) {
      axios.get(`/api/school/${id}`).then(({ data }) => {
        data.area = [data.pid, data.cid, data.did];
        setArea({
          pid: data.pid,
          province: data.province,
          cid: data.cid,
          city: data.city,
          did: data.did,
          district: data.district
        });
        setLocation(data.location)
        form.setFieldsValue(data);
        initMap(data.location ? data.location.coordinates : [])
      });
    } else if (visible) {
      setLocation({})
      initMap(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, visible, form]);

  return (
    <Fragment>
      {id ? (
        <a onClick={() => setVisible(true)}>编辑</a>
      ) : (
        <Button type="primary" onClick={() => setVisible(true)}>
          新增学校
        </Button>
      )}
      <Drawer
        destroyOnClose
        width="calc(100% - 200px)"
        title="学校管理"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Form {...layout} form={form} hideRequiredMark onFinish={onFinish}>
          <Form.Item label="学校名称" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="max"
            label="办学轨数"
            rules={[{ required: true }]}
            extra="比如一年级有5个班，三年级有6个班，那么就填6，最大40"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="省市区" name="area" rules={[{ required: true }]}>
            <Area onChange={onAreaChange} />
          </Form.Item>
          <Form.Item label="经纬度" rules={[{ required: false }]}>
            <p style={{marginTop:'6px'}}>{(location && location.coordinates? location.coordinates : []).join(" , ")}</p>
          </Form.Item>
          <Form.Item label="位置">
          <Spin spinning={loading}>
            <div style={{width:'100%',height:'380px'}}>
              <div id="amp-container" className="map" tabIndex="0" style={{width:'100%',height:'100%'}}></div>
              <div id="amp-panel" className="scrollbar1">
                <div id="amp-searchBar">
                  <input id="amp-searchInput" placeholder="输入学校名搜索学校" />
                </div>
                <div id="amp-searchResults"></div>
              </div>
            </div>
            </Spin>
          </Form.Item>
          <Form.Item
            label="接报姓名"
            name="recipients"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="接报电话" name="phone" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="接报地点"
            name="receiveAddress"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="接报方式"
            name="receiveMode"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>

      </Drawer>
    </Fragment>
  );
}

import React, { useState } from 'react';
import { Table, Card, Form, Select, Button, Row, Col, Input } from 'antd';
import { useRequest } from 'ahooks';
import axios from 'axios';
import dayjs from 'dayjs';

const { Column } = Table;
const { Option } = Select;

export default function List() {
  const [year, setYear] = useState('');
  const [term, setTerm] = useState('');
  const [school, setSchool] = useState('');
  const { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) =>
      axios({
        url: '/api/express/list',
        params: { page: current, size: pageSize, school, year, term },
      }),
    {
      paginated: true,
      defaultPageSize: 15,
    }
  );

  return (
    <Card bordered={false}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2>物流查询</h2>
        </Col>
        <Col>
          <Form layout="inline">
            <Form.Item label="学校">
              <Input
                placeholder="请输入学校关键字"
                onChange={(e) => setSchool(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="学年">
              <Select
                placeholder="请选择学年"
                style={{ width: 140 }}
                onChange={(value) => setYear(value)}
              >
                <Option value={2021}>2020-2021年</Option>
                <Option value={2022}>2021-2022年</Option>
                <Option value={2023}>2022-2023年</Option>
                <Option value={2024}>2023-2024年</Option>
                <Option value={2025}>2024-2025年</Option>
              </Select>
            </Form.Item>
            <Form.Item label="学期">
              <Select
                placeholder="请选择学期"
                style={{ width: 120 }}
                onChange={(value) => setTerm(value)}
              >
                <Option value={1}>上学期</Option>
                <Option value={2}>下学期</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => refresh()}>
                查询
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Table
        rowKey="id"
        bordered
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          total: data?.data?.count,
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column title="学校" key="schoolName" dataIndex="schoolName" />
        <Column
          title="学年"
          key="year"
          dataIndex="year"
          render={(year) => `${year - 1}-${year}年`}
        />
        <Column
          title="学期"
          key="term"
          dataIndex="term"
          render={(term) => `${term === 1 ? '上学期' : '下学期'}`}
        />
        <Column
          title="期数"
          key="current"
          dataIndex="current"
          render={(current) => `第${current}期`}
        />
        <Column title="物流公司" key="expressName" dataIndex="expressName" />
        <Column title="物流单号" key="expressNo" dataIndex="expressNo" />
        <Column title="发货人" key="shipperName" dataIndex="shipperName" />
        <Column title="内置物" key="inside" dataIndex="inside" />
        <Column
          title="时间"
          key="updateTime"
          dataIndex="updateTime"
          render={(date) => dayjs(date).format('YYYY-MM-DD HH:mm')}
        />
      </Table>
    </Card>
  );
}

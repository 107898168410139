import React, { useState, useEffect, Fragment } from 'react';
import { Form, Input, Select, message, Drawer, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

import Items from './Items';
import Edition from '../Edition/Select';

const { Option } = Select;

export default function Renewals({
  id,
  newspaperName,
  year,
  term,
  school,
  schoolName,
}) {
  const [grade, setGrade] = useState(1);
  const [math, setMath] = useState('');
  const [language, setLanguage] = useState('');
  const [max, setMax] = useState(0);
  const [report, setReport] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      axios.get(`/api/School/${school}`).then(({ data }) => {
        setMax(data.max);
      });
    }
  }, [school, visible]);

  const onRenewals = () => {
    Modal.confirm({
      title: '操作提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定要续订当前的上报吗?',
      okType: 'danger',
      onOk: () => {
        axios.put(`/api/Report?id=${id}`, {}).then(({ code, data, msg }) => {
          if (code) {
            message.error(msg);
          } else {
            setReport(data);
            setVisible(true);
          }
        });
      },
    });
  };

  return (
    <Fragment>
      <Typography.Link onClick={onRenewals}>续订</Typography.Link>
      <Drawer
        title="续订"
        placement="right"
        visible={visible}
        width="calc(100% - 200px)"
        onClose={() => setVisible(false)}
      >
        <Form layout="inline">
          <Form.Item label="报纸">
            <Input value={newspaperName} disabled />
          </Form.Item>
          <Form.Item label="学年">
            <Input value={`${year - 1}-${year}年`} disabled />
          </Form.Item>
          <Form.Item label="学期">
            <Input value={term === 1 ? '上学期' : '下学期'} disabled />
          </Form.Item>
          <Form.Item label="学校">
            <Input value={schoolName} disabled />
          </Form.Item>
        </Form>
        <Form layout="inline">
          <Form.Item label="年级">
            <Select
              value={grade}
              style={{ width: 120 }}
              onChange={(value) => setGrade(value)}
            >
              <Option value={1}>一年级</Option>
              <Option value={2}>二年级</Option>
              <Option value={3}>三年级</Option>
              <Option value={4}>四年级</Option>
              <Option value={5}>五年级</Option>
              <Option value={6}>六年级</Option>
            </Select>
          </Form.Item>
          <Form.Item label="语文">
            <Edition
              value={language}
              onChange={(value) => setLanguage(value)}
            />
          </Form.Item>
          <Form.Item label="数学">
            <Edition value={math} onChange={(value) => setMath(value)} />
          </Form.Item>
        </Form>
        {report?.id && max && grade && language && math && (
          <Items {...{ id: report.id, max, grade, language, math }} />
        )}
      </Drawer>
    </Fragment>
  );
}

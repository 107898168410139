import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';

export default function SchoolSelect({ autoSelect, ...props }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get('/api/school/list').then(({ data }) => {
      setOptions(
        data.items.map(({ id, name, max }) => ({ label: name, value: id, max }))
      );
      if (autoSelect) {
        props.onChange(data.items[0].id, data.items[0].max);
      }
    });
    // eslint-disable-next-line
  }, []);

  const onSelectChange = (selectedValue) => {
    let { value, max } = options.find((x) => x.value === selectedValue);
    props.onChange(value, max);
  };

  const onSelectSearch = (keywords) => {
    if(keywords){
  //  setTimeout(() => {
    axios.get('/api/school/list?name='+keywords).then(({ data }) => {
      if(data.items.length>0)
      setOptions(
        data.items.map(({ id, name, max }) => ({ label: name, value: id, max }))
      );});
     
  //  }, 300);
    }
  };

  if (!options.length) {
    return (
      <Select loading={true} placeholder="请选择学校" style={{ width: 200 }} />
    );
  }
  return (
    <Select
      {...props}
      options={options}
      placeholder="请选择学校"
      optionFilterProp="items"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: 200 }}
      onChange={onSelectChange}
      onSearch={onSelectSearch}
    />
  );
}

import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, message, Typography } from 'antd';
import axios from 'axios';

const { Option } = Select;

export default function UserModal({ data, onSuccess }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onFinish = (values) => {
    axios[data ? 'put' : 'post'](`/api/account/${data?.id || ''}`, values).then(
      ({ code, msg }) => {
        if (code === 0) {
          onSuccess();
          setVisible(false);
          message.success('添加成功');
        } else {
          message.error(msg);
        }
      }
    );
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(data);
    }
  }, [visible, data, form]);

  return (
    <Fragment>
      {data ? (
        <Typography.Link onClick={() => setVisible(true)}>编辑</Typography.Link>
      ) : (
        <Button type="primary" onClick={() => setVisible(true)}>
          添加账号
        </Button>
      )}
      <Modal
        title="账号管理"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{ role: 'salesman' }}
        >
          <Form.Item label="角色" name="role" rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Option value="admin">办公室</Option>
              <Option value="salesman">业务员</Option>
              <Option value="storekeeper">仓库管理员</Option>
            </Select>
          </Form.Item>
          <Form.Item label="姓名" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="账号" name="account" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {data ? null : (
            <Form.Item
              label="密码"
              name="password"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Fragment>
  );
}

import React, { useState, useEffect, Fragment } from 'react';
import { Card, Row, Col, Form, Select, Drawer, Typography } from 'antd';
import axios from 'axios';

import EditionSelect from '../Edition/Select';
import PaperSelect from '../Newspaper/Select';
import Items from './Items';

const { Option } = Select;


export default function Input({ schoolId, schoolMax, schoolName }) {
  const [newspaper, setNewspaper] = useState('');
  const [year, setYear] = useState(2023);
  const [term, setTerm] = useState(1);
  const [school, setSchool] = useState(schoolId);
  const [max, setMax] = useState(schoolMax);
  const [grade, setGrade] = useState(1);
  const [math, setMath] = useState('');
  const [language, setLanguage] = useState('');
  const [report, setReport] = useState(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (school && newspaper) {
      axios({
        url: '/api/Report/Pre',
        params: { school, newspaper, year, term },
      }).then(({ data }) => {
        if (data) {
          setReport(data);
        } else {
          axios
            .post('/api/report', { school, newspaper, year, term })
            .then(({ data }) => {
              setReport(data);
            });
        }
      });
    }
  }, [school, newspaper, year, term]);

  return (
    <Fragment>
      <Typography.Link onClick={() => setVisible(true)}>上报</Typography.Link>
      <Drawer
        width="calc(100% - 200px)"
        title="编辑上报"
        visible={visible}
        placement="right"
        onClose={() => setVisible(false)}
      >
        <Card bordered={false}>
          <Row justify="space-between" style={{ marginBottom: 12 }}>
            <Col>
              <h2>上报录入</h2>
            </Col>
          </Row>
          <Form layout="inline">
            <Form.Item label="报纸">
              <PaperSelect
                autoSelect
                value={newspaper}
                onChange={(value) => setNewspaper(value)}
              />
            </Form.Item>
            <Form.Item label="学年">
              <Select
                value={year}
                style={{ width: 140 }}
                onChange={(value) => setYear(value)}
              >
                <Option value={2021}>2020-2021年</Option>
                <Option value={2022}>2021-2022年</Option>
                <Option value={2023}>2022-2023年</Option>
                <Option value={2024}>2023-2024年</Option>
                <Option value={2025}>2024-2025年</Option>
              </Select>
            </Form.Item>
            <Form.Item label="学期">
              <Select
                value={term}
                style={{ width: 120 }}
                onChange={(value) => setTerm(value)}
              >
                <Option value={1}>上学期</Option>
                <Option value={2}>下学期</Option>
              </Select>
            </Form.Item>
            <Form.Item label="学校">
              <Select
                disabled
                value={school}
                onChange={(value, max) => {
                  setMax(max);
                  setSchool(value);
                }}>
                <Option value={school}>{schoolName}</Option>
              </Select>
            </Form.Item>
          </Form>
          <Form layout="inline">
            <Form.Item label="年级">
              <Select
                value={grade}
                style={{ width: 120 }}
                onChange={(value) => setGrade(value)}
              >
                <Option value={1}>一年级</Option>
                <Option value={2}>二年级</Option>
                <Option value={3}>三年级</Option>
                <Option value={4}>四年级</Option>
                <Option value={5}>五年级</Option>
                <Option value={6}>六年级</Option>
              </Select>
            </Form.Item>
            <Form.Item label="语文">
              <EditionSelect
                value={language}
                onChange={(value) => setLanguage(value)}
              />
            </Form.Item>
            <Form.Item label="数学">
              <EditionSelect value={math} onChange={(value) => setMath(value)} />
            </Form.Item>
          </Form>
          {report && <Items {...{ id: report.id, max, grade, language, math, school: school }} />}
        </Card>
      </Drawer>
    </Fragment >
  );
}

import React from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';

import styles from './Login.module.css';

export default function Login({ onLogin }) {
  const request = (values) => ({
    url: '/api/Account/SignOn',
    method: 'POST',
    data: values,
  });

  const { loading, run } = useRequest(request, {
    manual: true,
    onSuccess: ({ code, msg }) => {
      if (code === 0) {
        onLogin();
      } else {
        alert(msg);
      }
    },
  });

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img
            alt="报纸订阅"
            className={styles.logo}
            src={process.env.PUBLIC_URL + 'logo.png'}
          />
          <h1 className={styles.title}>报纸订阅</h1>
          <p className={styles.subtitle}>后台管理系统</p>
        </div>
        <Form className={styles.form} onFinish={(values) => run(values)}>
          <Form.Item
            name="account"
            rules={[{ required: true, message: '请输入账号！' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="账号"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码！' }]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="密码"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <div className={styles.footer}>
          <a href="/">忘记密码</a>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';

export default function NewspaperSelect(props) {
  const [options, setOptions] = useState([]);
  const { autoSelect, onChange, ...restProps } = props;

  useEffect(() => {
    axios.get('/api/newspaper/list').then(({ data }) => {
      setOptions(data.map(({ id, title }) => ({ label: title, value: id })));
      autoSelect && onChange(data[0].id);
    });
  }, [autoSelect, onChange]);

  if (!options.length) {
    return (
      <Select loading={true} placeholder="请选择报纸" style={{ width: 160 }} />
    );
  }

  return (
    <Select
      options={options}
      placeholder="请选择报纸"
      style={{ width: 160 }}
      onChange={onChange}
      {...restProps}
    />
  );
}

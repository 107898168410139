import React from 'react';
import { Layout, Col, Row, Menu, Dropdown } from 'antd';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { useStore } from 'hooks';

import Password from 'pages/Account/Password';

const { Header } = Layout;

export default function PageHeader() {
  const { user } = useStore();
  const { run } = useRequest('/api/Account/SignOff', {
    manual: true,
    onSuccess: () => {
      window.location.href = '/';
    },
  });

  const menu = (
    <Menu style={{ width: 160 }}>
      <Menu.Item>
        <Password>
          <SettingOutlined />
          <span style={{ marginLeft: 10 }}>修改密码</span>
        </Password>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => run()}>
          <LogoutOutlined />
          <span style={{ marginLeft: 10 }}>退出</span>
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ padding: '0 16px' }}>
      <Row justify="space-between">
        <Col />
        <Col>
          <Dropdown overlay={menu} arrow>
            <a style={{ marginRight: 12 }}>
              <UserOutlined style={{ marginRight: 4 }} />
              {user?.name}
            </a>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}

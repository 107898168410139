import React from 'react';
import { Table } from 'antd';
import { useRequest } from 'ahooks';

const { Column } = Table;

export default function DetailExpress({ id }) {
  const { data, loading } = useRequest({
    url: `/api/report/express`,
    params: { id },
  });

  return (
    <Table
      bordered
      rowKey="id"
      size="small"
      dataSource={data?.data}
      pagination={false}
      loading={loading}
      style={{ marginTop: 12 }}
    >
      <Column
        title="期数"
        key="current"
        dataIndex="current"
        render={(current) => `第${current}期`}
      />
      <Column
        align="center"
        title="物流公司"
        key="expressName"
        dataIndex="expressName"
      />
      <Column
        align="center"
        title="物流单号"
        key="expressNo"
        dataIndex="expressNo"
      />
      <Column
        align="center"
        title="发货人"
        key="shipperName"
        dataIndex="shipperName"
      />
      <Column align="center" title="内置物" key="inside" dataIndex="inside" />
    </Table>
  );
}

import React, { useState } from 'react';
import {
  Table,
  Card,
  Form,
  Select,
  Input,
  Button,
  Tag,
  Typography,
  Modal,
  message,
  Divider,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';
import dayjs from 'dayjs';
//import produce from 'immer';
import SchoolSelect from '../School/Select';
import UpdateModal from './Modal';

const { Column } = Table;
const { Option } = Select;
const { Link, Text } = Typography;

const ORDERSTATUS = ['待支付', '已支付', '已上报', '', '', '', '', '退款中','','已退款'];
const ORDERCOLORS = [
  'warning',
  'processing',
  'success',
  '',
  '',
  '',
  '',
  'success',
];

export default function List() {
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [year, setYear] = useState('');
  const [term, setTerm] = useState('');
  const [school, setSchool] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  let { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) =>
      axios({
        url: '/api/order/list',
        params: {
          page: page,
          size: size,
          status,
          name,
          phone,
          year,
          term,
          school,
        },
      }),
    {
      paginated: true,
      defaultPageSize: 15,
      refreshDeps:[page,size]
    }
  );

  const onRefund = (id) => {
    console.log(id);
    Modal.confirm({
      title: '操作提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定要退款吗？',
      okType: 'danger',
      onOk: () => {
        axios
          .put(`/api/order/refund`, {}, { params: { id } })
          .then(({ code, msg }) => {
            refresh();
            message[code ? 'error' : 'success'](msg);
          });
      },
    });
  };

  return (
    <Card bordered={false}>
      <h2>订单查询</h2>
      <Form layout="inline">
        <Form.Item label="状态">
          <Select
            style={{ width: 140 }}
            placeholder="请选择订单状态"
            onChange={(value) => setStatus(value)}
          >
            <Option value="">全部</Option>
            <Option value={0}>待支付</Option>
            <Option value={1}>已支付</Option>
            <Option value={2}>已上报</Option>
            <Option value={7}>退款中</Option>
            <Option value={9}>已退款</Option>
          </Select>
        </Form.Item>
        <Form.Item label="学年">
          <Select
            placeholder="请选择学年"
            style={{ width: 140 }}
            onChange={(value) => setYear(value)}
          >
            <Option value={2021}>2020-2021年</Option>
            <Option value={2022}>2021-2022年</Option>
            <Option value={2023}>2022-2023年</Option>
            <Option value={2024}>2023-2024年</Option>
            <Option value={2025}>2024-2025年</Option>
          </Select>
        </Form.Item>
        <Form.Item label="学期">
          <Select
            placeholder="请选择学期"
            style={{ width: 120 }}
            onChange={(value) => setTerm(value)}
          >
            <Option value={1}>上学期</Option>
            <Option value={2}>下学期</Option>
          </Select>
        </Form.Item>
        <Form.Item label="学校">
          <SchoolSelect onChange={(value) => setSchool(value)} showSearch  />
        </Form.Item>
        <Form.Item label="订阅人">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="订阅电话">
          <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => refresh()}>
            查询
          </Button>
        </Form.Item>
      </Form>
      <Table
        rowKey="id"
        bordered
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: false,
          current:page,
          pageSize:size,
          total: data?.data?.count,
          onChange:(page,size)=>{
            setPage(page);
            setSize(size);
          },
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column title="订单号" dataIndex="orderNo" key="orderNo" />
        <Column title="学校" dataIndex="schoolName" key="schoolName" />
        <Column
          title="学年"
          key="year"
          dataIndex="year"
          render={(year) => `${year - 1}-${year}年`}
        />
        <Column
          title="学期"
          key="term"
          dataIndex="term"
          render={(term) => `${term === 1 ? '上学期' : '下学期'}`}
        />
        <Column
          title="班级"
          key="class"
          render={(row) => `${row.grade}年级${row.class}班`}
        />
        <Column title="订阅人" dataIndex="name" key="name" />
        <Column title="订阅电话" dataIndex="phone" key="phone" />
        <Column
          title="(半/全)"
          dataIndex="half"
          key="half"
          render={(half) => (half === 1 ? '半年' : '全年')}
        />
        <Column title="数量" key="count" dataIndex="count" />
        <Column title="金额" key="amount" dataIndex="amount" />
        <Column
          title="状态"
          key="orderStatus"
          dataIndex="orderStatus"
          render={(status) => (
            <Tag color={ORDERCOLORS[status]}>{ORDERSTATUS[status]}</Tag>
          )}
        />
        <Column
          title="时间"
          key="createTime"
          dataIndex="createTime"
          render={(date) => dayjs(date).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          width={144}
          title="操作"
          key="action"
          render={(row) => (
            <>
              {row.orderStatus === 1 ? (
                <Link onClick={() => onRefund(row.id)}>退款</Link>
              ) : (
                <Text disabled>退款</Text>
              )}
              <Divider type="vertical" />
              {/* {row.orderStatus === 1 ? (
                <UpdateModal data={row} onSuccess={() => refresh()} />
              ) : (
                <Text disabled>修改班级</Text>
              )} */}
              <UpdateModal data={row} onSuccess={() => refresh()} />
            </>
          )}
        />
      </Table>
    </Card>
  );
}

import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Form, Select, message, Typography, Input } from 'antd';
import axios from 'axios';

const { Option } = Select;

export default function UserModal({ data, onSuccess }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [max, setMax] = useState(0);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onFinish = (values) => {
    axios
      .put('/api/order', values, { params: { id: data.id } })
      .then(({ code, msg }) => {
        if (code === 0) {
          onSuccess();
          setVisible(false);
        }
        message[code ? 'error' : 'success'](msg);
      });
  };

  useEffect(() => {
    if (visible) {
      axios.get(`/api/School/${data.school}`).then(({ data }) => {
        setMax(data.max);
      });
      form.setFieldsValue(data);
    }
  }, [visible, data, form]);

  return (
    <Fragment>
      <Typography.Link onClick={() => setVisible(true)}>
        修改班级
      </Typography.Link>
      <Modal
        title="修改学校班级"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{ role: 'salesman' }}
        >
          <Form.Item
            label="学校"
            name="schoolName"
            rules={[{ required: true }]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item label="年级" name="grade" rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Option value={1}>一年级</Option>
              <Option value={2}>二年级</Option>
              <Option value={3}>三年级</Option>
              <Option value={4}>四年级</Option>
              <Option value={5}>五年级</Option>
              <Option value={6}>六年级</Option>
            </Select>
          </Form.Item>
          <Form.Item label="班级" name="class" rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              {[...Array(max + 1)].map((_, index) => (
                <Option value={index} key={index}>
                  {index}班
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

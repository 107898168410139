import React from 'react';
import { Layout } from 'antd';

import Sider from './Sider';
import Header from './Header';
import Pages from 'pages';

const { Content } = Layout;

export default function PageLayout() {
  return (
    <Layout>
      <Sider />
      <Layout>
        <Header />
        <Content className="page-content">
          <Pages />
        </Content>
      </Layout>
    </Layout>
  );
}

import React, { Fragment } from 'react';
import { Steps } from 'antd';
import dayjs from 'dayjs';

const { Step } = Steps;

export default function DetailSteps({
  status,
  comment,
  submitTime,
  officeComment,
  officeTime,
  warehouseComment,
  warehouseTime,
}) {
  let current = 0;
  if (status === 1 || status === 3) {
    current = 1;
  } else if (status === 2 || status === 5) {
    current = 2;
  } else if (status === 4 || status === 6) {
    current = 3;
  }

  let stepStatus = 'process';
  if (status === 3 || status === 5) {
    stepStatus = 'error';
  } else if (status === 4 || status === 6) {
    stepStatus = 'finish';
  }

  return (
    <Fragment>
      <div
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        审核进度
      </div>
      <Steps current={current} status={stepStatus}>
        <Step
          title="上报"
          description={
            <Fragment>
              {comment}
              <br />
              {status > 0 && dayjs(submitTime).format('YYYY-MM-DD HH:mm')}
            </Fragment>
          }
        />
        <Step
          title="办公室审核"
          description={
            <Fragment>
              {officeComment}
              <br />
              {status > 1 && dayjs(officeTime).format('YYYY-MM-DD HH:mm')}
            </Fragment>
          }
        />
        <Step
          title="物流审核"
          description={
            <Fragment>
              {warehouseComment}
              <br />
              {status > 3 && dayjs(warehouseTime).format('YYYY-MM-DD HH:mm')}
            </Fragment>
          }
        />
        <Step title="完成" />
      </Steps>
    </Fragment>
  );
}

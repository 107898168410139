import React, { Fragment, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Modal,
  Table,
  Select,
  Tag,
  Input,
  message,
  Divider,
  Typography,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';
import dayjs from 'dayjs';

import UserModal from './Modal';
import Password from './Password';

const { Search } = Input;
const { Column } = Table;
const { Option } = Select;

const ROLES = {
  admin: '办公室',
  salesman: '业务员',
  storekeeper: '仓库管理员',
};

const COLORS = {
  admin: 'green',
  salesman: 'cyan',
  storekeeper: 'blue',
};

export default function List() {
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) =>
      `/api/account/List?page=${current}&size=${pageSize}&name=${name}&role=${role}`,
    {
      paginated: true,
      refreshDeps: [name, role],
      defaultPageSize: 15,
    }
  );

  const onDelete = (id, enable) => {
    Modal.confirm({
      title: '操作提示',
      icon: <ExclamationCircleOutlined />,
      content: `确定要${enable ? '禁用' : '启用'}账号吗?`,
      okType: 'danger',
      onOk: () => {
        axios.delete(`/api/account?id=${id}`).then(({ code, msg }) => {
          message[code ? 'error' : 'success'](code ? msg : '操作成功');
          refresh();
        });
      },
    });
  };

  return (
    <Card bordered={false}>
      <Row justify="space-between" style={{ marginBottom: 12 }}>
        <Col>
          <h2>账号管理</h2>
        </Col>
        <Col>
          <Select
            placeholder="请选择角色"
            style={{ width: 160 }}
            onChange={(value) => setRole(value)}
          >
            <Option value="">全部</Option>
            <Option value="admin">管理员</Option>
            <Option value="salesman">业务员</Option>
            <Option value="storekeeper">仓库管理员</Option>
          </Select>
          <Search
            placeholder="请输入姓名"
            onSearch={(value) => setName(value)}
            style={{ width: 200, margin: '0 16px' }}
          />
          <UserModal onSuccess={() => refresh()} />
        </Col>
      </Row>
      <Table
        bordered
        rowKey="id"
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          total: data?.data?.count,
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column title="账号" dataIndex="account" key="account" />
        <Column title="姓名" dataIndex="name" key="name" />
        <Column
          align="center"
          title="角色"
          dataIndex="roles"
          key="roles"
          render={(roles) =>
            roles.map((role) => (
              <Tag key={role} color={COLORS[role]}>
                {ROLES[role]}
              </Tag>
            ))
          }
        />
        <Column
          align="center"
          title="更新时间"
          dataIndex="updateTime"
          key="updateTime"
          render={(date) => dayjs(date).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          width={200}
          title="操作"
          key="action"
          render={(row) => (
            <Fragment>
              <UserModal data={row} onSuccess={() => refresh()} />
              <Divider type="vertical" />
              {row.enable ? (
                <a onClick={() => onDelete(row.id, row.enable)}>禁用</a>
              ) : (
                <Typography.Text disabled>禁用</Typography.Text>
              )}
              <Divider type="vertical" />
              <Password id={row.id} />
            </Fragment>
          )}
        />
      </Table>
    </Card>
  );
}

import React, { useState } from 'react';
import { Table, Card, Form, Select, Button, Row, Col } from 'antd';
import { useRequest } from 'ahooks';

import SchoolSelect from 'pages/School/Select';
import Salesman from 'pages/User/Select';

const { Column, ColumnGroup } = Table;
const { Option } = Select;

const Hans = ['', '一', '二', '三', '四', '五', '六'];

export default function List() {
  const [year, setYear] = useState(2023);
  const [term, setTerm] = useState(1);
  const [schoolId, setSchool] = useState('');
  const [user, setUser] = useState('');
  const { data, loading, refresh } = useRequest({
    url: '/api/statistics/report',
    params: {
      year,
      term,
      schoolId,
      user,
      pid: 410000,
    },
  });

  return (
    <Card bordered={false}>
      <Row justify="space-between">
        <Col>
          <h2>汇总统计</h2>
        </Col>
        <Col>
          <Form layout="inline">
            <Form.Item label="学年">
              <Select
                value={year}
                style={{ width: 140 }}
                placeholder="请选择学年"
                onChange={(value) => setYear(value)}
              >
                <Option value={2021}>2020-2021年</Option>
                <Option value={2022}>2021-2022年</Option>
                <Option value={2023}>2022-2023年</Option>
                <Option value={2024}>2023-2024年</Option>
                <Option value={2025}>2024-2025年</Option>
              </Select>
            </Form.Item>
            <Form.Item label="学期">
              <Select
                value={term}
                style={{ width: 120 }}
                placeholder="请选择学期"
                onChange={(value) => setTerm(value)}
              >
                <Option value={1}>上学期</Option>
                <Option value={2}>下学期</Option>
              </Select>
            </Form.Item>
            <Form.Item label="学校">
              <SchoolSelect onChange={(value) => setSchool(value)} showSearch optionFilterProp="items"
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} />
            </Form.Item>
            <Form.Item label="发行员">
              <Salesman onChange={(value) => setUser(value)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => refresh()}>
                统计
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Table
        bordered
        rowKey="grade"
        loading={loading}
        pagination={false}
        dataSource={data?.data?.items}
        style={{ marginTop: 12 }}
        summary={(data) => (
          <Table.Summary.Row>
            <Table.Summary.Cell align="center" index={0}>
              <div style={{ textAlign: 'right' }}>合计：</div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce((acc, cur) => acc + cur.onlineHalf, 0)}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce((acc, cur) => acc + cur.onlineFull, 0)}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce((acc, cur) => acc + cur.offlineHalf, 0)}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce((acc, cur) => acc + cur.offlineFull, 0)}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce((acc, cur) => acc + cur.presentedHalf, 0)}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce((acc, cur) => acc + cur.presentedFull, 0)}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce(
                  (acc, cur) =>
                    acc + cur.onlineHalf + cur.offlineHalf + cur.presentedHalf,
                  0
                )}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8}>
              <div style={{ textAlign: 'center' }}>
                {data.reduce(
                  (acc, cur) =>
                    acc + cur.onlineFull + cur.offlineFull + cur.presentedFull,
                  0
                )}
              </div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      >
        <Column
          align="center"
          title="年级"
          dataIndex="grade"
          key="grade"
          render={(grade) => `${Hans[grade]}年级`}
        />
        <ColumnGroup title="线上">
          <Column
            align="center"
            title="半年"
            key="onlineHalf"
            dataIndex="onlineHalf"
          />
          <Column
            align="center"
            title="全年"
            key="onlineFull"
            dataIndex="onlineFull"
          />
        </ColumnGroup>
        <ColumnGroup title="线下">
          <Column
            align="center"
            title="半年"
            key="offlineHalf"
            dataIndex="offlineHalf"
          />
          <Column
            align="center"
            title="全年"
            key="offlineFull"
            dataIndex="offlineFull"
          />
        </ColumnGroup>
        <ColumnGroup title="赠送">
          <Column
            align="center"
            title="半年"
            key="presentedHalf"
            dataIndex="presentedHalf"
          />
          <Column
            align="center"
            title="全年"
            key="presentedFull"
            dataIndex="presentedFull"
          />
        </ColumnGroup>
        <ColumnGroup title="合计">
          <Column
            align="center"
            title="半年"
            key="totalHalf"
            render={({ onlineHalf, offlineHalf, presentedHalf }) =>
              onlineHalf + offlineHalf + presentedHalf
            }
          />
          <Column
            align="center"
            title="全年"
            key="totalFull"
            render={({ onlineFull, offlineFull, presentedFull }) =>
              onlineFull + offlineFull + presentedFull
            }
          />
        </ColumnGroup>
      </Table>
    </Card>
  );
}

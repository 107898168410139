import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message, InputNumber } from 'antd';
import axios from 'axios';

export default function UserModal({ id, onClose }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onFinish = (values) => {
    axios({
      url: `/api/newspaper/${id || ''}`,
      method: id ? 'PUT' : 'POST',
      data: values,
    }).then(({ code, msg }) => {
      if (code === 0) {
        onClose();
        setVisible(false);
        return message.success('操作成功');
      }
      message.error(msg);
    });
  };

  useEffect(() => {
    if (id && visible) {
      axios.get(`/api/newspaper/${id}`).then(({ data }) => {
        form.setFieldsValue(data);
      });
    }
  }, [id, visible, form]);

  return (
    <Fragment>
      {id ? (
        <a onClick={() => setVisible(true)}>编辑</a>
      ) : (
        <Button type="primary" onClick={() => setVisible(true)}>
          新增报纸
        </Button>
      )}
      <Modal
        title="报纸管理"
        width={560}
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form {...layout} form={form} hideRequiredMark onFinish={onFinish}>
          <Form.Item label="报纸名称" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="半年" name="half" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="全年" name="full" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="描述"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

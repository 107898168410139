import React, { useState, Fragment } from 'react';
import { Drawer, Tabs } from 'antd';

import DetailItems from './DetailItems';
import DetailOrder from './DetailOrder';
import DetailExpress from './DetailExpress';
import DetailSchool from './DetailSchool';
import DetailSteps from './DetailSteps';

const { TabPane } = Tabs;

export default function Detail(props) {
  const [visible, setVisible] = useState(false);
  const { id, school, schoolName, year, term } = props;
  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>详情</a>
      <Drawer
        width="calc(100% - 200px)"
        title={`${year}年${term === 1 ? '上学期' : '下学期'}${schoolName}`}
        visible={visible}
        placement="right"
        onClose={() => setVisible(false)}
      >
        <DetailSchool id={school} />
        <DetailSteps {...props} />
        <Tabs defaultActiveKey="1" size="large" style={{ marginTop: 16 }}>
          <TabPane tab="上报数据" key="1">
            <DetailItems id={id} />
          </TabPane>
          <TabPane tab="在线订单" key="2">
            <DetailOrder id={id} />
          </TabPane>
          <TabPane tab="物流信息" key="3">
            <DetailExpress id={id} />
          </TabPane>
        </Tabs>
      </Drawer>
    </Fragment>
  );
}

import React, { Fragment, useState } from 'react';
import { Button, Modal, Form, Select, InputNumber, message } from 'antd';
import axios from 'axios';

import PaperSelect from '../Newspaper/Select';
import EditionSelect from '../Edition/Select';

const { Option } = Select;

export default function UserModal({ id, onSuccess }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const onFinish = (values) => {
    axios.post('/api/stock/postin', values).then(({ code, msg }) => {
      if (code === 0) {
        onSuccess();
        setVisible(false);
      }
      message[code ? 'error' : 'success'](msg);
    });
  };

  return (
    <Fragment>
      <Button type="primary" onClick={() => setVisible(true)}>
        入库
      </Button>
      <Modal
        title="入库管理"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          hideRequiredMark
          initialValues={{
            subject: '语文',
            year: 2022,
            term: 1,
            grade: 1,
            current: 1,
          }}
          onFinish={onFinish}
        >
          <Form.Item label="报纸" name="newspaper">
            <PaperSelect autoSelect onChange={() => { }} />
          </Form.Item>
          <Form.Item label="科目" name="subject">
            <Select style={{ width: 160 }} placeholder="请选择科目">
              <Option value="语文">语文</Option>
              <Option value="数学">数学</Option>
            </Select>
          </Form.Item>
          <Form.Item label="版本" name="edition">
            <EditionSelect />
          </Form.Item>
          <Form.Item label="学年" name="year">
            <Select style={{ width: 160 }}>
              <Option value={2021}>2020-2021年</Option>
              <Option value={2022}>2021-2022年</Option>
              <Option value={2023}>2022-2023年</Option>
              <Option value={2024}>2023-2024年</Option>
              <Option value={2025}>2024-2025年</Option>
            </Select>
          </Form.Item>
          <Form.Item label="学期" name="term">
            <Select style={{ width: 160 }}>
              <Option value={1}>上学期</Option>
              <Option value={2}>下学期</Option>
            </Select>
          </Form.Item>
          <Form.Item label="年级" name="grade">
            <Select style={{ width: 160 }}>
              <Option value={1}>一年级</Option>
              <Option value={2}>二年级</Option>
              <Option value={3}>三年级</Option>
              <Option value={4}>四年级</Option>
              <Option value={5}>五年级</Option>
              <Option value={6}>六年级</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="当前期数"
            name="current"
            rules={[{ required: true }]}
          >
            <Select style={{ width: 160 }} placeholder="请选择当前期数">
              <Option value={1}>第一期</Option>
              <Option value={2}>第二期</Option>
              <Option value={3}>第三期</Option>
              <Option value={4}>第四期</Option>
              <Option value={5}>第五期</Option>
              <Option value={6}>第六期</Option>
              <Option value={7}>第七期</Option>
              <Option value={8}>第八期</Option>
              <Option value={9}>第九期</Option>
              <Option value={10}>第十期</Option>
              <Option value={11}>第十一期</Option>
              <Option value={12}>第十二期</Option>
              <Option value={13}>第十三期</Option>
              <Option value={14}>第十四期</Option>
              <Option value={15}>第十五期</Option>
              <Option value={16}>第十六期</Option>
              <Option value={17}>第十七期</Option>
              <Option value={18}>第十八期</Option>
              <Option value={19}>第十九期</Option>
              <Option value={20}>第二十期</Option>
            </Select>
          </Form.Item>
          <Form.Item label="入库数量" name="count" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

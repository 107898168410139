import React from 'react';
//import { Typography } from 'antd';
import { useRequest } from 'ahooks';
import PreviewModal from '../Report/Stats';

export default function Status({ id, type }) {
  const { data, loading } = useRequest(`/api/School/${type}/${id}`, {
    formatResult: ({ data }) => data,
  });

  if (loading) return null;
  return data ? (<PreviewModal id={id} isLink={true} /> ): '';
}

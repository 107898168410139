import React,{Fragment,useState} from 'react';
import { Table, Typography,Tooltip,Dropdown,Button,Menu} from 'antd';
import { useRequest } from 'ahooks';

const { Column } = Table;
const { Link, Text } = Typography;

const Hans = ['', '一', '二', '三', '四', '五', '六'];

export default function DetailItems({ id }) {
  const [menuList,setMenuList] = useState([])
  const { data, loading } = useRequest(`/api/report/items?id=${id}`,{
    formatResult: ({ data }) => {
      const obj={}
      data.forEach(item => {
        obj[item.grade]={id:item.report,grade:item.grade}
      });
      setMenuList(Object.values(obj))
      return data
    }
  });
  const menu = (
    <Menu>
      <Menu.Item key={0}><a target="_blank" rel="noopener noreferrer" href={`https://db.hnweilaiedu.cn/api/Report/Roster?id=${id}`}>打包下载全部</a></Menu.Item>
      {menuList.map(v=><Menu.Item key={v.id}><a target="_blank" rel="noopener noreferrer" href={`https://db.hnweilaiedu.cn/api/Report/Roster?id=${v.id}&grade=${v.grade}`}>{Hans[v.grade]}年级名单下载</a></Menu.Item>)}
    </Menu>
  );
  return (
    <Table
      bordered
      rowKey="id"
      size="small"
      loading={loading}
      pagination={false}
      dataSource={data}
      style={{ marginTop: 12 }}
    >
      <Column
       title={
        <Fragment>
        <span>班级</span>
        <Dropdown overlay={menu} placement="bottomLeft" arrow>
          <Button type="link">下载名单</Button>
        </Dropdown>
        </Fragment>
        }
        key="classes"
        render={(row) => `${Hans[row.grade]}年级${row.class}班`}
      />
      <Column
        align="center"
        title="线下半年"
        key="offlineHalf"
        dataIndex="offlineHalf"
      />
      <Column
        align="center"
        title="线下全年"
        key="offlineFull"
        dataIndex="offlineFull"
      />
      <Column align="center" title="线上半年" dataIndex="onlineHalf" />
      <Column align="center" title="线上全年" dataIndex="onlineFull" />
      <Column align="center" title="赠送半年" dataIndex="presentedHalf" />
      <Column
        align="center"
        title="赠送全年"
        key="presentedFull"
        dataIndex="presentedFull"
      />
      <Column
        align="center"
        title="花名册"
        key="download"
        render={({ report, grade, onlineHalf, onlineFull, ...row }) =>
          onlineHalf + onlineFull > 0 ? (
            <Tooltip title={`下载${Hans[grade]}年级${row.class}班名单`} placement="rightTop" >
            <Link
              target="_blank"
              href={`https://db.hnweilaiedu.cn/api/Report/Roster?id=${report}&grade=${grade}&class=${row.class}`}
            >
              下载名单
            </Link>
            </Tooltip>
          ) : (
            <Text disabled>下载</Text>
          )
        }
      />
    </Table>
  );
}

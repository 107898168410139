import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Form, Input, Typography, Select, message } from 'antd';
import { useRequest } from 'ahooks';
import axios from 'axios';

const { Option } = Select;

export default function ExpressModal({ id }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState('');
  const { loading, run } = useRequest(
    (id, values) => ({
      url: '/api/Report/PostExpress',
      method: 'POST',
      params: { id },
      data: values,
    }),
    {
      manual: true,
      onSuccess: ({ code, msg }) => {
        if (code === 0) {
          setVisible(false);
        }
        message[code ? 'error' : 'success'](msg);
      },
    }
  );

  useEffect(() => {
    if (visible) {
      axios
        .get('/api/Report/ExpressPre', { params: { id, current } })
        .then(({ data }) => {
          form.setFieldsValue(data);
        });
    }
  }, [current, visible, form, id]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  return (
    <Fragment>
      <Typography.Link onClick={() => setVisible(true)}>
        上报物流
      </Typography.Link>
      <Modal
        width={640}
        title="上报物流"
        visible={visible}
        confirmLoading={loading}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          hideRequiredMark
          onFinish={(values) => run(id, values)}
        >
          <Form.Item
            label="当前期数"
            name="current"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="请选择当前期数"
              onChange={(value) => setCurrent(value)}
            >
              <Option value={1}>第一期</Option>
              <Option value={2}>第二期</Option>
              <Option value={3}>第三期</Option>
              <Option value={4}>第四期</Option>
              <Option value={5}>第五期</Option>
              <Option value={6}>第六期</Option>
              <Option value={7}>第七期</Option>
              <Option value={8}>第八期</Option>
              <Option value={9}>第九期</Option>
              <Option value={10}>第十期</Option>
              <Option value={11}>第十一期</Option>
              <Option value={12}>第十二期</Option>
              <Option value={13}>第十三期</Option>
              <Option value={14}>第十四期</Option>
              <Option value={15}>第十五期</Option>
              <Option value={16}>第十六期</Option>
              <Option value={17}>第十七期</Option>
              <Option value={18}>第十八期</Option>
              <Option value={19}>第十九期</Option>
              <Option value={20}>第二十期</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="物流公司"
            name="expressName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="物流单号"
            name="expressNo"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="发货人"
            name="shipperName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="内置物" name="inside">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

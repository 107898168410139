import React ,{useEffect,useState} from 'react';
import { Layout, Menu } from 'antd';
import {
  TagsOutlined,
  BarcodeOutlined,
  BankOutlined,
  ProjectOutlined,
  InteractionOutlined,
  StockOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
  ExportOutlined,
  ImportOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from 'hooks';

const { Sider } = Layout;

export default function PageSider() {
  const { user } = useStore();
  const { pathname } = useLocation();
  const [selectedKeys,setSelectedKeys]=useState([]);
  useEffect(() => {
    setSelectedKeys([pathname.split('/').join("")])
  },[pathname])
  return (
    <Sider breakpoint="lg" collapsedWidth="0">
      <div className="logo">
        <img src="/logo.png" alt="报纸订阅" />
        <h1>报纸订阅</h1>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[]}
        selectedKeys={selectedKeys}
      >
        {(user?.roles?.includes('admin') ||
          user?.roles?.includes('salesman')) && (
          <Menu.Item key="school" icon={<BankOutlined />}>
            <Link to="/school">学校列表</Link>
          </Menu.Item>
        )}
        {(user?.roles?.includes('admin') ||
          user?.roles?.includes('salesman')) && (
          <Menu.Item key="order" icon={<ProjectOutlined />}>
            <Link to="/order">订单查询</Link>
          </Menu.Item>
        )}
        <Menu.Item key="report" icon={<InteractionOutlined />}>
          <Link to="/report">
            {user?.roles?.includes('storekeeper') ? '仓库物流' : '上报管理'}
          </Link>
        </Menu.Item>
        {(user?.roles?.includes('admin') ||
          user?.roles?.includes('storekeeper')) && (
          <>
            <Menu.Item key="stock" icon={<StockOutlined />}>
              <Link to="/stock">库存管理</Link>
            </Menu.Item>
            <Menu.Item key="stockin" icon={<ImportOutlined />}>
              <Link to="/stock/in">入库管理</Link>
            </Menu.Item>
            <Menu.Item key="stockout" icon={<ExportOutlined />}>
              <Link to="/stock/out">出库记录</Link>
            </Menu.Item>
          </>
        )}
        <Menu.Item key="express" icon={<BarcodeOutlined />}>
          <Link to="/express">物流查询</Link>
        </Menu.Item>
        <Menu.Item key="stats" icon={<TableOutlined />}>
          <Link to="/stats">汇总统计</Link>
        </Menu.Item>
        {user?.roles?.includes('admin') && (
          <Menu.Item key="newspaper" icon={<ReadOutlined />}>
            <Link to="/newspaper">报纸管理</Link>
          </Menu.Item>
        )}
        {user?.roles?.includes('admin') && (
          <Menu.Item key="edition" icon={<TagsOutlined />}>
            <Link to="/edition">版本管理</Link>
          </Menu.Item>
        )}
        {user?.roles?.includes('admin') && (
          <Menu.Item key="user" icon={<UsergroupAddOutlined />}>
            <Link to="/user">账号管理</Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
}

import React, { useState, useEffect, Fragment } from 'react';
import { Button, Table, Input, message } from 'antd';
import produce from 'immer';
import axios from 'axios';

import Stats from './Stats';

const { Column } = Table;
const Hans = ['', '一', '二', '三', '四', '五', '六'];

function Items({ id, grade, math, language, max,school }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get('/api/report/items', { params: { id, grade } })
      .then(({ data }) => {
        const result = [];
        for (let i = 0; i <= max; i++) {
          let item = data.find((x) => x.class === i);
          result.push(
            item
              ? item
              : {
                  grade,
                  class: i,
                  offlineFull: '',
                  offlineHalf: '',
                  presentedFull: '',
                  presentedHalf: '',
                }
          );
        }
        setItems(result);
      });
  }, [id, max, grade]);

  const onInput = (event, index, field) => {
    const { value } = event.target;
    setItems(
      produce((draft) => {
        draft[index][field] = value;
      })
    );
  };

  const onSubmit = () => {
    setLoading(true);
    axios({
      url: `/api/Report/PostItems`,
      params: { id },
      method: 'POST',
      data: items.map(
        ({
          offlineFull,
          offlineHalf,
          presentedFull,
          presentedHalf,
          ...rest
        }) => ({
          ...rest,
          offlineFull: Number(offlineFull),
          offlineHalf: Number(offlineHalf),
          presentedFull: Number(presentedFull),
          presentedHalf: Number(presentedHalf),
          language,
          math,
        })
      ),
    }).then(({ code, msg }) => {
      setLoading(false);
      if (code === 0) {
        return message.success('提交成功');
      }
      message.error(msg);
    });
  };

  return (
    <Fragment>
      <Table
        bordered
        size="small"
        rowKey="class"
        dataSource={items}
        pagination={false}
        style={{ marginTop: 8 }}
      >
        <Column
          align="center"
          title="班级"
          key="classes"
          render={(row) => `${Hans[row.grade]}年级${row.class}班`}
        />
        <Column
          align="center"
          title="半年"
          key="offlineHalf"
          dataIndex="offlineHalf"
          render={(value, _, index) => (
            <Input
              value={value}
              onChange={(value) => onInput(value, index, 'offlineHalf')}
            />
          )}
        />
        <Column
          align="center"
          title="全年"
          key="offlineFull"
          dataIndex="offlineFull"
          render={(value, _, index) => (
            <Input
              value={value}
              onChange={(value) => onInput(value, index, 'offlineFull')}
            />
          )}
        />
        <Column
          align="center"
          title="赠半"
          key="presentedHalf"
          dataIndex="presentedHalf"
          render={(value, _, index) => (
            <Input
              value={value}
              onChange={(value) => onInput(value, index, 'presentedHalf')}
            />
          )}
        />
        <Column
          align="center"
          title="赠全"
          key="presentedFull"
          dataIndex="presentedFull"
          render={(value, _, index) => (
            <Input
              value={value}
              onChange={(value) => onInput(value, index, 'presentedFull')}
            />
          )}
        />
      </Table>
      <div style={{ marginTop: 24 }}>
        <Button type="primary" loading={loading} onClick={onSubmit}>
          提交
        </Button>
        <Stats id={school}/>
      </div>
    </Fragment>
  );
}

export default React.memo(Items);

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';

export default function EditionSelect(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get('/api/edition/list').then(({ data }) => {
      setOptions(
        data.items.map(({ id, name }) => ({ label: name, value: id }))
      );
      props.onChange(data.items[0].id);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Select
      options={options}
      placeholder="请选择版本"
      style={{ width: 120 }}
      {...props}
    />
  );
}

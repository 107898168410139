import React from 'react';
import { Table } from 'antd';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';

const { Column } = Table;

export default function DetailOrder({ id }) {
  const { data, loading, pagination } = useRequest(
    ({ current, pageSize }) =>
      `/api/report/order?id=${id}&page=${current}&size=${pageSize}`,
    {
      paginated: true,
      defaultPageSize: 15,
    }
  );

  return (
    <Table
      bordered
      size="small"
      rowKey="id"
      dataSource={data?.data?.items}
      pagination={{
        ...pagination,
        showSizeChanger: true,
        total: data?.data?.count,
        showTotal: (total) => `共 ${total} 条数据`,
      }}
      loading={loading}
      style={{ marginTop: 12 }}
    >
      <Column title="订单号" dataIndex="orderNo" key="orderNo" />
      <Column title="订阅人" dataIndex="name" key="name" />
      <Column
        title="班级"
        key="class"
        render={(row) => `${row.grade}年级${row.class}班`}
      />
      <Column
        title="(半/全)"
        dataIndex="half"
        key="half"
        render={(half) => (half === 1 ? '半年' : '全年')}
      />
      <Column title="数量" key="count" dataIndex="count" />
      <Column title="支付金额" key="amount" dataIndex="amount" />
      <Column
        title="支付时间"
        key="payTime"
        dataIndex="payTime"
        render={(date) => dayjs(date).format('YYYY-MM-DD HH:mm')}
      />
    </Table>
  );
}

import React, { Fragment, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useRequest } from 'ahooks';
import axios from 'axios';

function resetPassword(id, values) {
  return axios({
    url: '/api/Account/ResetPassword',
    method: 'POST',
    params: { id },
    data: values,
  });
}

export default function Password({ id }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { loading, run } = useRequest(resetPassword, {
    manual: true,
    onSuccess: ({ code, msg }) => {
      if (code === 0) {
        setVisible(false);
      }
      message[code ? 'error' : 'success'](msg);
    },
  });

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>重置密码</a>
      {visible && (
        <Modal
          title="重置密码"
          visible={visible}
          confirmLoading={loading}
          onOk={() => form.submit()}
          onCancel={() => setVisible(false)}
        >
          <Form
            {...layout}
            form={form}
            hideRequiredMark
            onFinish={(values) => run(id, values)}
          >
            <Form.Item
              label="新密码"
              name="Password"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="确认新密码"
              name="ConfrimPassword"
              rules={[{ required: true }]}
              extra="为了你的账号安全，密码至少包含8位字符"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Fragment>
  );
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Input from './Input';

export default function News() {
  return (
    <Switch>
      <Route exact path="/report" component={List} />
      <Route path="/report/input" component={Input} />
    </Switch>
  );
}

import React, { useState } from 'react';
import { Divider, Table, Card, Row, Col, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';
import dayjs from 'dayjs';

import EditionModal from './Modal';

const { Column } = Table;

export default function List() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) =>
      `/api/edition/list?page=${page}&size=${size}`,
    {
      paginated: true,
      defaultPageSize: 15,
      refreshDeps:[page,size]
    }
  );

  const onDelete = (id) => {
    Modal.confirm({
      title: '删除提示',
      icon: <ExclamationCircleOutlined />,
      content: '数据删除后不可恢复，请谨慎操作',
      okType: 'danger',
      onOk: () => {
        axios.delete(`/api/edition/${id}`).then(({ code, msg }) => {
          refresh();
          message[code ? 'error' : 'success'](code ? msg : '删除成功');
        });
      },
    });
  };

  return (
    <Card bordered={false}>
      <Row justify="space-between" style={{ marginBottom: 12 }}>
        <Col>
          <h2>版本管理</h2>
        </Col>
        <Col>
          <EditionModal onClose={() => refresh()} />
        </Col>
      </Row>
      <Table
        bordered
        rowKey="id"
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: false,
          current:page,
          pageSize:size,
          total: data?.data?.count,
          onChange:(page,size)=>{
            setPage(page);
            setSize(size);
          },
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column title="版本名称" dataIndex="name" key="name" />
        <Column title="简称" dataIndex="shortName" key="shortName" />
        <Column
          width="20%"
          title="更新时间"
          dataIndex="updateTime"
          key="updateTime"
          render={(date) => dayjs(date).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          width={120}
          title="操作"
          key="action"
          dataIndex="id"
          render={(id) => (
            <>
              <EditionModal id={id} onClose={() => refresh()} />
              <Divider type="vertical" />
              <a onClick={() => onDelete(id)}>删除</a>
            </>
          )}
        />
      </Table>
    </Card>
  );
}

import React, { Fragment, useState } from 'react';
import { Modal, Form, Input, Radio, message } from 'antd';
import axios from 'axios';

const TERM = ['', '上学期', '下学期'];

export default function UserModal({ data, onClose }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { id, schoolName, year, term } = data;

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (values) => {
    axios({
      url: '/api/Report/Office',
      method: 'POST',
      params: { id },
      data: values,
    }).then(({ code, msg }) => {
      if (code === 0) {
        onClose();
        setVisible(false);
        return message.success('操作成功');
      }
      message.error(msg);
    });
  };

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>办公室审核</a>
      <Modal
        title="办公室审核"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{ status: 2 }}
        >
          <Form.Item label="学校">
            {`${schoolName}${year - 1}-${year}年${TERM[term]}`}
          </Form.Item>
          <Form.Item label="审核" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={2}>同意</Radio>
              <Radio value={3}>驳回</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="备注"
            name="officeComment"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

import React from 'react';
import { ConfigProvider, Spin } from 'antd';
import { HashRouter as Router } from 'react-router-dom';
import zhCN from 'antd/es/locale/zh_CN';
import { useRequest, UseRequestProvider } from 'ahooks';
import axios from 'axios';

import Login from 'pages/Account/Login';
import Layout from 'layouts';

import { useStore } from 'hooks';

import './App.css';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://db.hnweilaiedu.cn';
}
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

function App() {
  const { setUser } = useStore();
  const { data, run, loading } = useRequest('/api/account', {
    onSuccess: ({ data }) => setUser(data),
  });

  if (loading) {
    return <Spin className="page-spining" />;
  }
  if (data.code === 401) {
    return <Login onLogin={() => run()} />;
  }
  return (
    <ConfigProvider locale={zhCN} input={{ autoComplete: 'off' }}>
      <Router>
        <Layout />
      </Router>
    </ConfigProvider>
  );
}

export default function RootApp() {
  return (
    <UseRequestProvider
      value={{
        requestMethod: (param) => axios(param),
      }}
    >
      <App />
    </UseRequestProvider>
  );
}
